'use strict';

import slick from 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

import PhotoSwipe from '../../../node_modules/photoswipe/dist/photoswipe.esm.js'
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

import PhotoSwipeDynamicCaption from './photoswipe-dynamic-caption-plugin.esm.js';
import './photoswipe-dynamic-caption-plugin.css';


import Masonry from "masonry-layout";

import {
    isDrugged
} from "../dragged.js";


const initMasonry = () => {
    
        let msnry = new Masonry('#references-masonry', {
            columnWidth: '.grid-sizer',
            gutter: '.gutter-sizer',
            itemSelector: '.instance',
            resize: true,
            percentPosition: true
        });

        $("#references-masonry").find('img').each(function(){
            let img = new Image() ;
            img.src = this.src;
            img.onload = function(){
                msnry.layout();
            };
        });

        //msnry.on('layoutComplete', hidePreloader());
};

jQuery(function () {
    if ($('#references-masonry').length > 0) {
        initMasonry();
    }

    $('#references').find('.instance').each(function(){
        const maxWidth = $(this).attr('data-width');
        $(this).css({
            'max-width': maxWidth + 'px',
        })
    })

    const slickHolder = $('#references-snippet').find('#references')

    //Init Slick
    slickHolder.slick({
        infinite: false,
        slidesToScroll: 2,
        autoplay: false,
        autoplaySpeed: 10000,
        variableWidth: true,
        dots: false,
        arrows: false,
        responsive: [{
            breakpoint: 767,
            settings: {
                slidesToScroll: 1,
            }
        }]
    });

    slickHolder.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        if(nextSlide == 0){
            $('.slicknav.prev').removeClass('active');
        }
        else{
            $('.slicknav.prev').addClass('active');
        }
        if(nextSlide + 1 == slick.$slides.length){
            $('.slicknav.next').removeClass('active');
        }
        else{
            $('.slicknav.next').addClass('active');
        }
    });




    $('#references, #references-masonry').find('.instance').on('click', function (e) {
        if (!isDrugged()) {
            e.preventDefault();

            let options = {
                dataSource: [],
                pswpModule: () => import('../../../node_modules/photoswipe/dist/photoswipe.esm.js'),
            };

            $(this).find('.sources').find('.gallery-image-source').each(function () {
                options.dataSource.push({
                    src: $(this).attr('data-src'),
                    width: $(this).attr('data-width'),
                    height: $(this).attr('data-height'),
                    alt: $(this).attr('data-alt'),
                    caption: $(this).html()
                })
            })

            options.index = 0;
            const pswp = new PhotoSwipe(options);

            pswp.on('uiRegister', function () {
                pswp.ui.registerElement({
                    name: 'custom-caption',
                    order: 9,
                    isButton: false,
                    appendTo: 'root',
                    html: 'Caption text',
                    onInit: (el, pswp) => {
                        pswp.on('change', () => {
                            const caption = options['dataSource'][pswp.currIndex].caption;
                            el.innerHTML = caption || '';
                        });
                    }
                });
            });


            pswp.on('close', () => {
                pswp.destroy();
            });
            pswp.init();
        }
    })


    // const lightbox = new PhotoSwipeLightbox({
    //     gallery: '#references',
    //     children: 'a',
    //     pswpModule: () => import('photoswipe')
    // });
    // lightbox.init();

    // $('#hgm-home-slider-slides').on('afterChange', function(event, slick, nextSlide){
    //     const len = slick.slideCount;
    //     let next = nextSlide + 1;
    //     if(next >= len){
    //         next = 0;
    //     }
    //     console.log(next)
    //     const slickNextSlide = $(slick.$slides.get(next));
    //     console.log(slick)
    //     $('#hgm-home-slider-slides').find('.slide').removeClass('active');
    //     slickNextSlide.find('.slide').addClass('active');
    // })

    //Slick Nav
    $('.slicknav').on('click', function (e) {
        e.preventDefault();
        if($(this).hasClass('next')){
            slickHolder.slick('slickNext');
        }
        if($(this).hasClass('prev')){
            slickHolder.slick('slickPrev');
        }
    })

});